var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "The input is a functional part in an application, For implementing it we have the "
        ),
        _c("code", [_vm._v("vs-input")]),
        _vm._v(" component."),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input", {
            staticClass: "inputx",
            attrs: { placeholder: "Placeholder" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-input", {
            staticClass: "inputx",
            attrs: { disabled: "", placeholder: "Disabled" },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <div class="centerx default-input">\n    <vs-input class="inputx" placeholder="Placeholder" v-model="value1" />\n    <vs-input disabled class="inputx" placeholder="Disabled" v-model="value2" />\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      value1: \'\',\n      value2: \'\'\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }