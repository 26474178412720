var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c(
        "span",
        [
          _vm._v(
            "You can change the color of the borders when the focus the input with the property "
          ),
          _c("code", [_vm._v("color")]),
          _vm._v(". You are able to use the "),
          _c("router-link", { attrs: { to: "/colors" } }, [
            _vm._v("Main Colors"),
          ]),
          _vm._v(" or "),
          _c("strong", [_vm._v("strong")]),
          _vm._v(" and "),
          _c("strong", [_vm._v("HEX")]),
          _vm._v(" colors."),
        ],
        1
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5 mb-3",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input", {
            staticClass: "mb-2",
            attrs: { "label-placeholder": "Default" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-input", {
            staticClass: "mb-2",
            attrs: { color: "success", "label-placeholder": "Success" },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-input", {
            staticClass: "mb-2",
            attrs: { color: "danger", "label-placeholder": "Danger" },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
          _c("vs-input", {
            staticClass: "mb-2",
            attrs: { color: "warning", "label-placeholder": "Warning" },
            model: {
              value: _vm.value4,
              callback: function ($$v) {
                _vm.value4 = $$v
              },
              expression: "value4",
            },
          }),
          _c("vs-input", {
            attrs: { color: "dark", "label-placeholder": "Dark" },
            model: {
              value: _vm.value5,
              callback: function ($$v) {
                _vm.value5 = $$v
              },
              expression: "value5",
            },
          }),
          _c("vs-input", {
            attrs: {
              color: "rgb(213, 14, 151)",
              "label-placeholder": "RGB: rgb(213, 14, 151)",
            },
            model: {
              value: _vm.value6,
              callback: function ($$v) {
                _vm.value6 = $$v
              },
              expression: "value6",
            },
          }),
          _c("vs-input", {
            attrs: { color: "#11e988", "label-placeholder": "HEX: #11e988" },
            model: {
              value: _vm.value7,
              callback: function ($$v) {
                _vm.value7 = $$v
              },
              expression: "value7",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n\n<template>\n    <div class="demo-alignment">\n      <vs-input label-placeholder="Default" v-model="value1"/>\n      <vs-input color="success" label-placeholder="Success" v-model="value2"/>\n      <vs-input color="danger" label-placeholder="Danger" v-model="value3"/>\n      <vs-input color="warning" label-placeholder="Warning" v-model="value4"/>\n      <vs-input color="dark" label-placeholder="Dark" v-model="value5"/>\n      <vs-input color="rgb(213, 14, 151)" label-placeholder="RGB: rgb(213, 14, 151)" v-model="value6"/>\n      <vs-input color="#11e988" label-placeholder="HEX: #11e988" v-model="value7"/>\n    </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:\'\',\n      value2:\'\',\n      value3:\'\',\n      value4:\'\',\n      value5:\'\',\n      value6:\'\',\n      value7:\'\'\n    }\n  }\n}\n</script>\n\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }