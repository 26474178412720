var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Label Placeholder", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "The placeholder can become a label when the input is focused. Use the property "
        ),
        _c("code", [_vm._v("label-placeholder")]),
        _vm._v(" for making this."),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input", {
            attrs: { "label-placeholder": "Label-placeholder" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-input", {
            attrs: {
              disabled: "true",
              "label-placeholder": "Label-placeholder-disabled",
            },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <div class="centerx">\n    <vs-input label-placeholder="Label-placeholder" v-model="value1"/>\n    <vs-input disabled="true" label-placeholder="Label-placeholder-disabled" v-model="value2"/>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:\'\',\n      value2:\'\'\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }