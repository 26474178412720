var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Validations", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can add a state for example of in affirmative response to a validation with "
        ),
        _c("code", [_vm._v("vs-success")]),
        _vm._v(
          " and if it is necessary to add a description or help to the user you can do it with the property "
        ),
        _c("code", [_vm._v("vs-description")]),
      ]),
      _c("p", [
        _vm._v("You can add icon using "),
        _c("code", [_vm._v("val-icon-{}")]),
        _vm._v(" and value will be icon name"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "Each of the states you can change the text, for example in the case of "
            ),
            _c("code", [_vm._v("vs-danger-text")]),
            _vm._v(" for the state of danger."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input", {
            attrs: {
              success: true,
              "success-text": "The mail is valid",
              placeholder: "Email Success",
              "val-icon-success": "icon-check",
              "val-icon-pack": "feather",
            },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-input", {
            attrs: {
              danger: true,
              "danger-text": "Wrong password",
              placeholder: "Password Danger",
              "val-icon-danger": "icon-x",
              "val-icon-pack": "feather",
            },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-input", {
            attrs: {
              warning: true,
              "warning-text": "The your data could not be verified",
              "val-icon-warning": "icon-alert-triangle",
              "val-icon-pack": "feather",
            },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
          _c("vs-input", {
            attrs: {
              "description-text": "Enter first two digit of your phone",
              placeholder: "Phone Description",
            },
            model: {
              value: _vm.value4,
              callback: function ($$v) {
                _vm.value4 = $$v
              },
              expression: "value4",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <div class="centerx">\n    <vs-input\n        :success="true"\n        success-text="The mail is valid"\n        placeholder="Email Success"\n        val-icon-success="icon-check"\n        val-icon-pack="feather"\n        v-model="value1" />\n\n    <vs-input\n        :danger="true"\n        danger-text="Wrong password"\n        placeholder="Password Danger"\n        val-icon-danger="icon-x"\n        val-icon-pack="feather"\n        v-model="value2" />\n\n    <vs-input\n        :warning="true"\n        warning-text="The your data could not be verified"\n        val-icon-warning="icon-alert-triangle"\n        val-icon-pack="feather"\n        v-model="value3" />\n\n    <vs-input\n        description-text="Enter first two digit of your phone"\n        placeholder="Phone Description"\n        v-model="value4" />\n    </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:\'\',\n      value2:\'\',\n      value3:\'\',\n      value4:\'\',\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }