var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "form-element-input-demo" } },
    [
      _c("input-default"),
      _c("input-label"),
      _c("input-label-placeholder"),
      _c("input-icon"),
      _c("input-color"),
      _c("input-validation"),
      _c("input-size"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }