var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Size", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("Control the size of input using "),
        _c("code", [_vm._v("size")]),
        _vm._v(". The allowed values are: "),
        _c("code", [_vm._v("large")]),
        _vm._v(" and "),
        _c("code", [_vm._v("small")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input", {
            staticClass: "inputx",
            attrs: { size: "large", placeholder: "Size large" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-input", {
            staticClass: "inputx",
            attrs: { placeholder: "Size Default" },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-input", {
            staticClass: "inputx",
            attrs: { size: "small", placeholder: "Size small" },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n\n<template>\n    <div class="centerx">\n       <vs-input size="large" class="inputx" placeholder="Size large" v-model="value1"/>\n       <vs-input class="inputx" placeholder="Size Default" v-model="value2"/>\n       <vs-input size="small" class="inputx" placeholder="Size small" v-model="value3"/>\n    </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:\'\',\n      value2:\'\',\n      value3:\'\'\n    }\n  }\n}\n</script>\n\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }