var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("The input can have icons. Use the property "),
        _c("code", [_vm._v("vs-icon")]),
        _vm._v(
          ". You can also also manipulate the icon's position with the property "
        ),
        _c("code", [_vm._v("vs-icon-after")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v("Vuesax uses the "),
            _c("strong", [_vm._v("Google Material Icons")]),
            _vm._v(
              " font library. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page.")]
            ),
          ]),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "FontAwesome and other fonts library are supported. Simply use the "
            ),
            _c("code", [_vm._v("vs-icon-pack")]),
            _vm._v(" with "),
            _c("code", [_vm._v("fa")]),
            _vm._v(" or "),
            _c("code", [_vm._v("fas")]),
            _vm._v(
              ". You still need to include the Font Awesome icons in your project."
            ),
          ]),
        ]
      ),
      _c("br"),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input", {
            staticClass: "is-label-placeholder",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-search",
              placeholder: "Search",
            },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-input", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-edit-2",
              "icon-after": "",
              placeholder: "icon-after",
              "vs-placeholder": "Nombre",
            },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("vs-input", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-plus",
              "label-placeholder": "Label-placeholder",
            },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
          _c("vs-input", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-shopping-cart",
              "icon-after": "",
              "vs-icon-after": "true",
              "label-placeholder": "Label-placeholder",
            },
            model: {
              value: _vm.value4,
              callback: function ($$v) {
                _vm.value4 = $$v
              },
              expression: "value4",
            },
          }),
          _c("vs-input", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-alert-circle",
              "label-placeholder": "icon-disabled",
              disabled: "true",
            },
            model: {
              value: _vm.value5,
              callback: function ($$v) {
                _vm.value5 = $$v
              },
              expression: "value5",
            },
          }),
          _c("vs-input", {
            attrs: {
              "icon-pack": "feather",
              icon: "icon-mail",
              placeholder: "icon-no-border",
              "icon-no-border": "",
            },
            model: {
              value: _vm.value6,
              callback: function ($$v) {
                _vm.value6 = $$v
              },
              expression: "value6",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <div class="centerx">\n        <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="value1" class="is-label-placeholder" />\n        <vs-input icon-pack="feather" icon="icon-edit-2" icon-after placeholder="icon-after" vs-placeholder="Nombre" v-model="value2" />\n        <vs-input icon-pack="feather" icon="icon-plus" label-placeholder="Label-placeholder" v-model="value3" />\n        <vs-input icon-pack="feather" icon="icon-shopping-cart" icon-after vs-icon-after="true" label-placeholder="Label-placeholder" v-model="value4" />\n        <vs-input icon-pack="feather" icon="icon-alert-circle" label-placeholder="icon-disabled" disabled="true" v-model="value5" />\n        <vs-input icon-pack="feather" icon="icon-mail" placeholder="icon-no-border" v-model="value6" icon-no-border />\n    </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:\'\',\n      value2:\'\',\n      value3:\'\',\n      value4:\'\',\n      value5:\'\',\n      value6:\'\',\n      value7:\'\',\n      value8: \'\'\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }