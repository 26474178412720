var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Label", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("Add a label to the input with the property "),
        _c("code", [_vm._v("label")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input", {
            attrs: { label: "Name", placeholder: "Your Name" },
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("vs-input", {
            attrs: {
              type: "password",
              label: "Password",
              placeholder: "Your Password",
            },
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <div class="centerx labelx">\n      <vs-input label="Name" placeholder="Your Name" v-model="value1"/>\n      <vs-input type="password" label="Password" placeholder="Your Password" v-model="value2"/>\n    </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      value1:\'\',\n      value2:\'\'\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }